@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-validation-error {
  border: 1px solid red;
}
.msg-validation-error {
  color: red;
}
.page-full {
    background-color: #f2f5f7;
    height: 100%;
}


.block-comodo {
    flex: 0 1 49%;
    box-sizing: border-box;
    border: 1px solid #253a5a;
    border-radius: 5px;
    margin: 3px;
    box-shadow: 0 0 3px 0px #c2c2c2;
}

@media (max-width: 768px) {
    .block-comodo {
        flex: 1 1 50%;
    }
}

.block-comodo .tittle {
    display: block;
    text-align: center;
    background-color: #253a5a;
    color: white;
}

.comodo-objects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 15px 0;
    font-size: 40px;
    color: #b5b5b5;
    position: relative;
}

.comodo-user {
    position: absolute;
    color: white;
    top: 10px;
    font-size: 50px;
    border: 1px solid;
    border-radius: 50%;
    padding: 5px;
    background: #7fd60e;
    opacity: .5;
}

.comodo-item {
    flex: 1 0 33%;
    text-align: center;
}
.innerContainer {
    display: flex !important;
}

.sidebar-user-info {
    width: 270px;
    padding: 20px 0;
    color:white;
}
.header-content-info {
    padding: 20px 15px;
    display: flex;
    flex:1 1;
    justify-content: space-between;
    align-items: center;
}

.header-content-info h3 {
    margin: 0;
}

.menu-toogle-open {
    display: none;
    align-items: center;
    color: white;
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .menu-toogle-open {
        display: flex;
    }
    .sidebar-user-info{
        display: none;
    }

    .header-content-info {
        display: flex;
        flex:1 1;
        justify-content: space-between;
        align-items: center;
    }

    .header-content-info h3 {
        font-size: 1.4rem;
    }
}

.notification-closer {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
}

.notification-area {
    position: relative;
}

.notification-area .notification-counter {
    color:white;
    font-weight: bold;
    background-color: red;
    padding: 0px 7px;
    border-radius: 50%;
    position: absolute;
    top: -10px;
    right: -10px;
}

.notification-area .notification-content {
    background: white;
    border-radius: 5px;
    position: absolute;
    box-shadow: 0px 0px 20px 0px #00000061;
    right: -15px;
    z-index: 100;
    padding: 15px;
    top: 35px;
    width: 400px;
}
.menu-wrapper {
    min-width: 270px;
    max-width: 270px;
    background-color: #fff;
    padding: 22px 0;
    margin-right: 15px;
    /* justify-content: space-between; */
    flex-direction: column;
    border-radius: 3px;
    display: flex;
}

.menu-title {
    font-size: 14px;
    font-weight: bold;
    color: #a8a8b1;
    margin-left: 23px;
}
.menu-wrapper ul{
    list-style: none;
    padding: 0;
}

.metismenu li > ul > li a {
    padding-left: 50px;
    /* color: #a8a8b1; */
    font-size: 14px;
}

.metismenu li a.active {
    background-color: rgba(0,0,0,.05);
}

.metismenu-icon {
    margin-right: 7px;
    color: #a8a8b1;
}

li > a {
    display: block;
    padding: 10px 20px;
    color: #404656;
}

.metismenu a {
    text-decoration: none;
}

.menu-toogle-close {
    display: none;
    font-size: 1.3rem;
    margin-bottom: 5px;
    font-weight: bold;
    color: #a8a8b1;
    margin-left: 23px;
}

.menu-shader {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    display: none;
    background-color: transparent;
    transition: 1s;
}

.menu-user-info {
    margin-left: 23px;
    margin-bottom: 10px;
    display: none;
}

.logout-menu-button {
    padding: 0 20px;
    display: none;
}

@media (max-width: 992px) {
    .menu-wrapper{
        padding: 10px 0;
    }
}
@media (max-width: 768px) {
    
    .menu-toogle-close {
        display: block;
    }

    .menu-wrapper{
        transition: .5s;
        position: fixed;
        z-index: 100;
        top: 0;
        bottom: 0;
        left: -270px;
        border-radius: 0;
    }

    .logout-menu-button, .menu-user-info {
        display: block;
    }

    .menu-open {
        left: 0px;
    }
    .menu-shader.open {
        display: block;
        background-color: rgba(0,0,0,.3);
    }

}

.different-profile-alert {
    border-radius: 0;
    font-size: 80%;
}
.indicador .card {
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.indicador .card * + * {
    margin: 0;
}

.alertas {
    padding-bottom: 1.25rem;
    text-align: left;
    margin-bottom: 10px !important;
}
.alertas:last-child {
    margin-bottom:0 !important;
}

.alertas .timestamp {
    position: absolute;
    right: 5px;
    bottom: 0;
}

.mqtt-info-box {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #000;
    color: #FFF;
    padding: 7px;
    margin-top: 5px;
    margin-left: 5px;
    border-radius: 4px;
    font-size: 11px;
    top: 50;
    left: 50;
}
.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
  }
  .form-signin .checkbox {
    font-weight: 400;
  }
  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
  }

